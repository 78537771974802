export default [

  {
    path: '/audit/access-logs',
    name: 'access-logs',
    component: () => import('@/views/audit-logs/AuditLogs.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Access Logs', active: true },
      ],
      pageTitle: 'Access Logs',
      allowAnonymous: false,
      section: 'Audit Log',
    },
  },
  {
    path: '/audit/change-logs',
    name: 'change-logs',
    component: () => import('@/views/audit-logs/AuditLogs.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Change Logs', active: true },
      ],
      pageTitle: 'Change Logs',
      rule: 'editor',
      allowAnonymous: false,
      section: 'Audit Log',
    },
  },
]
