<template>
  <b-card
    no-body
    style="height: 60vh;"
  >
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-overlay :show="isLoading" class="h-100">
        <!-- apex chart -->
        <vue-apex-charts
          v-if="$isNotEmpty(series)"
          type="donut"
          :height="height"
          class="my-1"
          :options="chartOptions"
          :series="series"
        />

        <!-- chart info -->
        <div
          v-for="(data, key, index) in chartInfo"
          :key="key"
          class="d-flex justify-content-between"
          :class="index === Object.keys(chartInfo).length - 1 ? '' : 'mb-1'"
        >
          <div class="series-info d-flex align-items-center">
            <feather-icon
              icon="CircleIcon"
              size="16"
              :style="`color: ${data.color}`"
            />
            <div class="font-weight-bold text-capitalize ml-75">{{
              key
            }}</div>
          </div>

          <div>{{ data.value }}</div>
        </div>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Pie Chart',
    },

    chartData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    colors: {
      type: Array,
      default: () => {
        return [this.$themeColors.primary, this.$themeColors.dark, this.$themeColors.danger]
      },
    },

    height: {
      type: String,
      default: '250',
    },
  },
  data() {
    return {
      renderChart: true,
      series: [],
      chartInfo: {},
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        labels: [],
        colors: [],
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
      },
    }
  },
  watch: {
    isLoading(newVal) {
      if (newVal === false) {
        this.initComponent()
      }
    },
  },
  created() {
    this.initComponent()
  },
  methods: {
    initComponent() {
      this.chartOptions.labels = []
      this.series = []
      this.chartOptions.colors = []
      this.chartInfo = {}

      Object.entries(this.chartData).forEach(([key, value], index) => {
        this.chartOptions.labels.push(key)
        this.series.push(value)
        this.chartOptions.colors.push(this.colors[index])

        this.chartInfo[key] = {
          'value': value,
          'color': this.colors[index],
        }
      })
    }
  },
}
</script>

<style>

</style>