export default [
  {
    path: '/companies-manager',
    name: 'companies',
    component: () => import('@/views/company/Company.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Companies | BETA', active: true },
      ],
      pageTitle: 'Companies',
      rule: 'editor',
      // group: ["superuser", "applicant", "employee", "elevated"],
      allowedAccountTypes: ['agency'],
      allowAnonymous: false,
      section: 'User Manager',
    },
  },

  {
    path: '/companies-manager/:id',
    name: 'companies-view',
    component: () => import('@/views/company/company-view/CompanyView.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Company Details | BETA', active: true },
      ],
      pageTitle: 'Company Details | BETA',
      rule: 'editor',
      // group: ["superuser", "applicant", "employee", "elevated"],
      // allowedUserGroups: ['superuser', 'employee', 'elevated'],
      // allowedAccountTypes: ['agency', 'employer'],
      allowAnonymous: false,
      section: 'User Manager',
    },
  },

  {
    path: '/companies-manager/:id/edit',
    name: 'companies-edit',
    component: () => import('@/views/company/company-edit/CompanyEdit.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Edit Company Details | BETA', active: true },
      ],
      pageTitle: 'Edit Company Details | BETA',
      rule: 'editor',
      // group: ["superuser", "applicant", "employee", "elevated"],
      allowedUserGroups: ['superuser', 'employee', 'elevated'],
      allowedAccountTypes: ['agency', 'employer'],
      allowAnonymous: false,
      section: 'User Manager',
    },
  },
]
