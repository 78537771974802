/* eslint-disable prefer-destructuring */
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueHammer } from 'vue2-hammer'
import VueCalendly from 'vue-calendly'
import VCalendar from 'v-calendar'
import VueSocialSharing from 'vue-social-sharing'
import VueSocial from '@growthbunker/vuesocial'
import VueCountdown from '@chenfengyuan/vue-countdown'

import { $themeColors } from '@themeConfig'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import { format } from 'date-fns'

import { v4 as uuidv4 } from 'uuid'
import useJwt from '@/auth/jwt/useJwt'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebook,
  faFacebookMessenger,
  faFacebookSquare,
  faWhatsappSquare,
  faLinkedin,
  faTelegram,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons'

import { AccountTypes, UserGroups } from '@/constants/enums'
import { CompanyUen } from '@/constants/company-uen'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vuesax from 'vuesax'

import * as faSolid from '@fortawesome/free-solid-svg-icons'
import * as faRegular from '@fortawesome/free-regular-svg-icons'

import { bucket, singleUpload } from '@/components/awsS3'

import VueTour from 'vue-tour'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins

import '@/libs/portal-vue'
import '@/libs/toastification'

// START VueSax
import 'vuesax/dist/vuesax.css'

// For more options see below
// Vue.use(VueReCaptcha, { siteKey: '6LcnJY8gAAAAAJgciNQmVPQcuZFRNcCZPiHZTkXb' })

library.add(
  faFacebook,
  faFacebookMessenger,
  faFacebookSquare,
  faWhatsappSquare,
  faLinkedin,
  faTelegram,
  faTwitterSquare,
)

const faIconList = [
  'faBuilding',
  'faColumns',
  'faComputerMouse',
  'faDollarSign',
  'faExclamationCircle',
  'faHandPointer',
  'faInfinity',
  'faMapMarkerAlt',
  'faPlus',
  'faQuestionCircle',
  'faStar',
  'faUserTie',
  'faList',
  'faCalendarDays',
]
faIconList.forEach(iconName => {
  library.add(faSolid[iconName])
  if (iconName in faRegular) {
    library.add(faRegular[iconName])
  }
})

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

const socketURL = process.env.VUE_APP_SOCKET_URL
const isDebug = process.env.VUE_APP_DEBUG || false

const socket = io(socketURL, {
  transports: ['websocket', 'polling', 'flashsocket'],
})

Vue.use(VueCalendly)

Vue.use(VCalendar)

Vue.use(VueSocialSharing)

Vue.use(VueHammer)

Vue.use(VueSocial)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueSocketIOExt, socket)

Vue.use(vuesax)
// END VueSax

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component(VueCountdown.name, VueCountdown)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$longestTimeout = 10000
Vue.prototype.$fileUploadTimeout = 99999999
Vue.prototype.$themeColors = $themeColors

Vue.prototype.$toastContent = ToastificationContent

Vue.prototype.$checkRights = (section, requiredRight) => {
  const { permissions } = store.state.auth.ActiveUser

  if (section) {
    let allRights = permissions.filter(item => {
      if (item.section === section && item[requiredRight]) {
        return true
      }
    })
    if (allRights.length > 0) {
      allRights = allRights[0]
      return true
    }

    return false
  }

  // if (perm_rights.includes(specific)) {
  //   return true
  // }
  return true
}
Vue.prototype.$checkFullName = (fullname, options = { nullable: false }) => {
  let state = true
  let message = ''

  const formatName = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/

  if (options.nullable) {
    if (Vue.prototype.$isNotEmpty(fullname)) {
      if ((fullname.length <= 2)) { // if fullname less than 2 char
        state = false
        message = 'A valid fullname is required (only letters allowed)'
      }

      if (formatName.test(fullname)) { // if contain specaial character
        state = false
        message = 'A valid fullname is required (only letters allowed)'
      }
    }
  } else if (fullname === undefined) {
    state = false
    message = 'A valid fullname is required (only letters allowed)'
  } else {
    if ((fullname.length <= 2)) { // if fullname less than 2 char
      state = false
      message = 'A valid fullname is required (only letters allowed)'
    }

    if (formatName.test(fullname)) { // if contain specaial character
      state = false
      message = 'A valid fullname is required (only letters allowed)'
    }
  }

  return { state, message }
}
Vue.prototype.$checkOfficialName = officialName => {
  let state = true
  let message = ''

  if (officialName === undefined) {
    state = false
    message = 'A valid official name is required'
  } else {
    if ((officialName.length <= 2)) { // if fullname less than 2 char
      state = false
      message = 'A valid official name is required'
    }

    const formatName = /[!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?]+/
    if (formatName.test(officialName)) { // if contain specaial character
      state = false
      message = 'A valid official name is required'
    }
  }

  return { state, message }
}
Vue.prototype.$checkEmail = email => {
  let state = true
  let message = ''

  // if email is not valid
  // const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  // const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?)*$/

  if (!email.match(emailRegex)) {
    state = false
    message = 'Please input a valid email address'
  }

  return {
    state,
    message,
  }
}
Vue.prototype.$limitPhoneNumber = phoneNumber => {
  // const result = String(phoneNumber).match('/\d{4}/')
  // return phoneNumber.length() < 8 ? phoneNumber : phoneNumber.substring(0, 8);
  const specialChars = '`!@#$%^&*()_+\-=\[\]{};\':"\\|,.<>\/?~'

  if (phoneNumber === null) {
    return null
  }

  if (phoneNumber.includes(specialChars)) {
    return null
  }

  const result = String(phoneNumber).substring(0, 8)
  return result
}
Vue.prototype.$checkPhoneNumber = phoneNumber => {
  let state = true
  let message = ''

  const rgx = /^\+(\d{1,3})\d{7,15}$/

  if (!rgx.test(phoneNumber)) {
    state = false
    message = 'You should add a valid phone number that contains your country code'
  }

  return { state, message }
}
Vue.prototype.$checkTeam = value => {
  let state = true
  let message = ''
  const allowedChars = ['-', '_', "'", '&']

  const escapedChars = allowedChars.map(char => `\\${char}`)
  const charClass = `[a-zA-Z0-9 ${escapedChars.join('')}]`
  const pattern = `^${charClass}*$`

  if (Vue.prototype.$isNotEmpty(value) && !value.match(pattern)) {
    state = false
    const allowedCharString = allowedChars.map(char => `( ${char} )`).join(', ')
    message = `Only ${allowedCharString}, digits and letters are allowed`
  }

  return { state, message }
}
Vue.prototype.$checkCompanySummary = value => {
  let state = true
  let message = ''
  const allowedChars = ['.', ',', '-', '_', "'", '"', '=', '$', '&', '/', '?']

  const escapedChars = allowedChars.map(char => `\\${char}`)
  const charClass = `[a-zA-Z0-9 ${escapedChars.join('')}]`
  const pattern = `^${charClass}*$`

  if (Vue.prototype.$isNotEmpty(value) && !value.match(pattern)) {
    state = false
    const allowedCharString = allowedChars.map(char => `( ${char} )`).join(', ')
    message = `Only ${allowedCharString}, digits and letters are allowed`
  }

  return { state, message }
}

Vue.prototype.$validateNRIC = nricNo => {
  const regex = new RegExp(/^[STFGM]\d{7}[A-Z]$/)
  const result = regex.test(nricNo)

  return result
}

Vue.prototype.$validateGeneralAcceptedString = (value, additionalSpecialChar = []) => {
  let state = true
  let message = ''
  let allowedChars = ['.', ',', '-', "'", '"', '/']
  allowedChars = [...allowedChars, ...additionalSpecialChar]

  const escapedChars = allowedChars.map(char => `\\${char}`)
  const charClass = `[a-zA-Z0-9 ${escapedChars.join('')}]`
  const pattern = `^${charClass}*$`

  if (Vue.prototype.$isNotEmpty(value) && !value.match(pattern)) {
    state = false
    const allowedCharString = allowedChars.map(char => `( ${char} )`).join(', ')
    message = `Only ${allowedCharString}, digits and letters are allowed`
  }

  return { state, message }
}

Vue.prototype.$formatDate = oldDate => {
  if (Vue.prototype.$isEmpty(oldDate) || oldDate === '-') {
    return '-'
  }

  // const formatString = 'yyyy-MM-dd'
  const formatString = 'do LLL, yyyy'

  if (typeof oldDate.getMonth === 'function') { // check if date is already a Date()
    return format(oldDate, formatString)
  }

  return format(new Date(oldDate), formatString)
}
Vue.prototype.$formatDatetime = (oldDate, withSeconds = false) => {
  if (Vue.prototype.$isEmpty(oldDate) || oldDate === '-') {
    return '-'
  }

  let formatString = 'do LLL, yyyy HH:mm'
  // let formatString = 'yyyy-MM-dd HH:mm'
  if (withSeconds) {
    formatString += ':ss'
  }

  if (typeof oldDate.getMonth === 'function') { // check if date is already a Date()
    return format(oldDate, formatString)
  }

  return format(new Date(oldDate), formatString)
}
Vue.prototype.$formatTime = (oldTime, withSeconds = false) => {
  if (Vue.prototype.$isEmpty(oldTime) || oldTime === '-') {
    return '-'
  }

  const now = new Date()

  let formatString = 'HH:mm'
  if (withSeconds) {
    formatString += ':ss'
  }

  if (typeof oldTime.getMonth === 'function') { // check if date is already a Date()
    return format(oldTime, formatString)
  }

  const datePlaceholder = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()} ${oldTime}`

  return format(new Date(datePlaceholder), formatString)
  // return format(new Date(), formatString)
}

Vue.prototype.$utcToSGT = utcDateTimeString => {
  //* 1. Parse the UTC datetime string into a Date object
  const utcDate = new Date(utcDateTimeString)

  //* 2. Adjust the time zone offset to Singapore Standard Time (UTC+08:00)
  const sgtOffset = 8 * 60 * 60 * 1000 // 8 hours in milliseconds
  const sgtDate = new Date(utcDate.getTime() + sgtOffset)

  //* 3. Format the date and time in SGT format (YYYY-MM-DD HH:mm:ss)
  // const formattedSGTDateTime = sgtDate.toLocaleString('en-SG', {
  //   timeZone: 'Asia/Singapore',
  //   year: 'numeric',
  //   month: '2-digit',
  //   day: '2-digit',
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   second: '2-digit',
  // })

  return sgtDate
}

Vue.prototype.$removeUnderscore = (string, capitalize = true) => {
  if (Vue.prototype.$isEmpty(string)) {
    return string
  }

  let stringArray = string.split('_')

  if (capitalize === true) {
    stringArray = stringArray.map(value => Vue.prototype.$capitalizeString(value))
  }

  return stringArray.join(' ')
}
Vue.prototype.$capitalizeString = string => string.charAt(0).toUpperCase() + string.slice(1)
Vue.prototype.$formatCurrency = value => {
  if (Vue.prototype.$isNotEmpty(value)) {
    return value.toLocaleString('en-US')
  }
  return value
}

Vue.prototype.$attachQuerySymbol = value => (value.length > 0 ? '&' : '?')
Vue.prototype.$bucketUrl = `https://${bucket}.s3.ap-southeast-1.amazonaws.com`

Vue.prototype.$simulateEnter = (e, outerThis, refName) => {
  const event = new KeyboardEvent('keydown', { key: 'Enter', keyCode: 13 })
  outerThis.$refs[refName].dispatchEvent(event)
}
Vue.prototype.$handleFilePondInit = (outerThis, id, ref = 'pond-file', folder_path = 'others', idx = 0) => {
  if (outerThis.$refs[ref]._pond) {
    outerThis.$refs[ref]._pond.setOptions({
      server: {
        process(fieldName, file, metadata, load, error, progress, abort) {
          if ('isLoading' in outerThis) {
            outerThis.isLoading = true
          }
          const extension = file.name.split('.').pop()
          singleUpload(file, folder_path, `${id}.${extension}`, load, error, progress, abort, null, outerThis, idx)
        },
      },
    })
  }
}

Vue.prototype.$isE2i = company_name => {
  if (Vue.prototype.$isNotEmpty(company_name)) {
    if (['e2i', 'employment and employability inc', 'employment and employability institute pte. ltd.'].includes(company_name.toLowerCase())) {
      return true
    }
  }

  return false
}

Vue.prototype.$addParamsToUrl = (outerThis, keyName, value) => {
  if (outerThis.$isNotEmpty(value)) {
    outerThis.$router.push({ path: outerThis.$route.fullPath, query: { [keyName]: value } })
  }
}

Vue.prototype.$removeUtmQuery = url => {
  url = new URL(url)
  if (url.searchParams.get('utm_source')) {
    localStorage.setItem('utm_source', url.searchParams.get('utm_source'))
  }
  if (url.searchParams.get('utm_medium')) {
    localStorage.setItem('utm_medium', url.searchParams.get('utm_medium'))
  }
  if (url.searchParams.get('utm_campaign')) {
    localStorage.setItem('utm_campaign', url.searchParams.get('utm_campaign'))
  }

  url.searchParams.delete('utm_source')
  url.searchParams.delete('utm_medium')
  url.searchParams.delete('utm_campaign')

  const updatedUrl = url.toString()
  return updatedUrl
}

Vue.prototype.$generateDownloadUrl = async (fileName, fileType = 'nil') => {
  try {
    if (fileName) {
      if (fileName.startsWith('/')) {
        fileName = fileName.slice(1)
      }
      const downloadResp = await Vue.prototype.$http.get(`/api/download-pre-signed-url/${fileName.replace('/', '+')}/${fileType.replace('/', '+')}`)
      const responseData = downloadResp.data
      const downloadUrl = responseData.download_url
      return downloadUrl
    }
  } catch (error) {
    return null // or handle the error in an appropriate way
  }
}

Vue.prototype.$thousandSeparated = value => {
  if (Vue.prototype.$isNotEmpty(value)) {
    return value.toLocaleString('en-US')
  }
  return 0
}

Vue.prototype.$generatePresignedUrl = async (file_name = '', method = 'GET', bucket_name = '') => {
  const resp = new Promise((res, rej) => {
    Vue.prototype.$http
      .post('/api/s3/generate-presigned-url', {
        form_data: {
          file_name,
          method,
          bucket,
        },
      })
      .then(response => {
        res(response.data)
      })
      .catch(() => {})
  })

  const result = await resp.then(response => response)

  return result
}

Vue.prototype.$downloadCSV = (arrayOfObject = [], csvFilename = 'data.csv', headers=[]) => { // eslint-disable-line
  let array = typeof arrayOfObject !== 'object' ? JSON.parse(arrayOfObject) : arrayOfObject
  let str = ''

  // make data uniform because some of the field can be missing
  const skeleton = {}
  const titleSkeleton = {}

  // Create the skeleton object
  if (headers.length > 0) {
    headers.forEach(header => {
      skeleton[header.columnName] = ''
      titleSkeleton[header.label] = ''
    })
  } else {
    array.forEach(item => {
      Object.keys(item).forEach(key => {
        skeleton[key] = ''
      })
    })
  }

  // Extend the skeleton with original data
  array = array.map(item => ({ ...skeleton, ...item }))
  // array = array.map(item => ({ ...skeleton }))

  // append header
  let line = ''
  for (const index in titleSkeleton) { // eslint-disable-line
    if (line !== '') line += ', '
    line += `"${index}"`
  }
  str += line + '\r\n' // eslint-disable-line

  // append content
  for (let i = 0; i < array.length; i++) { // eslint-disable-line
    line = ''
    for (const index in skeleton) { // eslint-disable-line
      if (line !== '') line += ', '
      // const value = array[i][index] ? array[i][index].toString().replace('"', '').replace("'", '').replace('“', '').replace('”', '').replace('\n', '') : array[i][index]
      // const value = array[i][index] ? array[i][index].toString().replace(/\n/g, '\n').replace(/\r/g, '\r') : array[i][index]
      const value = array[i][index] ? array[i][index].toString().replace(/"/g, '`').replace(/"/g, '`') : array[i][index]

      line += `"${value}"`

      // const value = array[i][index]
      // line += `"${encodeURIComponent(value)}"`

      // line += `"${value}"`
    }

    // line += `"${array[i][index]}"`
    str += line + '\r\n' // eslint-disable-line
  }

  const anchor = document.createElement('a')
  // anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(str)}`
  anchor.href = `data:Application/octet-stream,${encodeURIComponent(str)}`
  anchor.target = '_blank'
  anchor.download = csvFilename
  anchor.click()
}

Vue.prototype.$isEmpty = value => {
  if (value === undefined) return true
  if (value === null) return true
  if (value === '') return true
  if (value === 'Not Set') return true

  if (typeof (value) === 'object') {
    if (Object.keys(value).length <= 0) return true

    if (Object.keys(value).length === 1) {
      if (value[0] === null || value[0] === '') {
        return true
      }
    }
  }

  if (typeof (value) === 'array') {
    if (Object.keys(value).length <= 0) return true

    if (Object.keys(value).length === 1) {
      if (value[0] === null || value[0] === '') {
        return true
      }
    }
  }

  return false
}

// Vue.prototype.$isNotEmpty = value => !Vue.prototype.$isEmpty(value)
Vue.prototype.$isNotEmpty = (value, retVal = false) => {
  if (!Vue.prototype.$isEmpty(value)) {
    if (retVal) {
      return value
    }

    return true
  }

  return false
}

Vue.prototype.$length = object => {
  if (Vue.prototype.$isEmpty(object)) {
    return 0
  }
  if (typeof object === 'object') {
    object = Object.entries(object)
  }
  return object.length
}

Vue.prototype.$generateUUID4 = () => uuidv4()

Vue.prototype.$vh = v => {
  const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  return (v * h) / 100
}
Vue.prototype.$vw = v => {
  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  return (v * w) / 100
}
Vue.prototype.$getGridVh = () => {
  const screenZoom = Math.round(window.devicePixelRatio * 100)
  let vh = 55

  if (screenZoom > 200) {
    vh = 50
  } else if (screenZoom > 175) {
    vh = 70
  } else if (screenZoom > 155) {
    vh = 50
  } else if (screenZoom > 105) {
    vh = 55
  } else if (screenZoom > 90) {
    vh = 65
  } else if (screenZoom > 80) {
    vh = 75
  } else if (screenZoom > 50) {
    vh = 85
  }
  return vh
}

Vue.prototype.$isMobile = () => {
  const screenWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth
  if (screenWidth <= 768) {
    return true
  }
  return false
}

Vue.prototype.$isTablet = () => {
  const screenWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth
  if (screenWidth > 768 && screenWidth <= 992) {
    return true
  }
  return false
}

Vue.prototype.$isSmallLaptop = () => {
  const screenWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth
  if (screenWidth > 992 && screenWidth <= 1200) {
    return true
  }
  return false
}

Vue.prototype.$isLargeLaptop = () => {
  const screenWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth
  if (screenWidth > 1200) {
    return true
  }
  return false
}
Vue.prototype.$AccountTypes = AccountTypes
Vue.prototype.$UserGroups = UserGroups
Vue.prototype.$CompanyUen = CompanyUen

Vue.prototype.$toastContent = ToastificationContent
Vue.prototype.$toastSuccess = (title = 'Success', text = '') => {
  Vue.$toast(
    {
      component: Vue.prototype.$toastContent,
      props: {
        title,
        icon: 'CheckCircleIcon',
        text,
        variant: 'success',
      },
    },
    { timeout: Vue.prototype.$longestTimeout },
  )
}
Vue.prototype.$toastDanger = (title = 'An Error Occured', text = 'Please try again later.') => {
  Vue.prototype.$toast(
    {
      component: Vue.prototype.$toastContent,
      props: {
        title,
        icon: 'AlertCircleIcon',
        text,
        variant: 'danger',
        bodyClass: 'text-break text-wrap',
      },
    },
    { timeout: Vue.prototype.$longestTimeout },
  )
}
Vue.prototype.$toastWarning = (title = 'Warning', text = '') => {
  Vue.prototype.$toast(
    {
      component: Vue.prototype.$toastContent,
      props: {
        title,
        icon: 'AlertCircleIcon',
        text,
        variant: 'warning',
        // bodyClass: 'text-break',
      },
    },
    { timeout: Vue.prototype.$longestTimeout },
  )
}
Vue.prototype.$toastInfo = (title = 'Info', text = '') => {
  Vue.prototype.$toast(
    {
      component: Vue.prototype.$toastContent,
      props: {
        title,
        icon: 'AlertCircleIcon',
        text,
        variant: 'info',
        position: 'center',
      },
    },
    { timeout: Vue.prototype.$longestTimeout },
  )
}

Vue.prototype.$htmlToVNode = (outerThis, html) => [
  outerThis.$createElement('div', { domProps: { innerHTML: html } }),
]

Vue.prototype.$handleErrorResponse = (
  error,
  title = 'An Error Occured',
  message = null,
  options = { showToast: false },
) => new Promise((resolve, reject) => {
  // alert('an error occured')

  const { data } = error.response
  let errorList = null
  if (data.errors?.body) {
    errorList = JSON.parse(data.errors?.body[0].replace(/'/g, '"'))
  }

  const serverMessage = []
  if (errorList) {
    errorList.forEach(err => {
      if (typeof err === 'object') {
        serverMessage.push(err.message)
      }
    })
  }

  if ('showToast' in options && options.showToast) {
    Vue.prototype.$toastDanger(
      title || 'An Error Occured',
      message || serverMessage || error.response?.data?.message || 'Please try again later.',
    )
  }

  resolve()
})

Vue.prototype.$simpleConfirmModal = (outerThis, text = 'Continue with the action?', options = {}) => new Promise((resolve, reject) => {
  const finalOptions = {
    title: 'Confirmation',
    size: 'sm',
    okVariant: 'success',
    okTitle: 'Confirm',
    cancelTitle: 'Cancel',
    cancelVariant: 'outline-secondary',
    hideHeaderClose: false,
    centered: true,
  }
  if (Vue.prototype.$isNotEmpty(options)) {
    Object.keys(options).forEach(key => {
      finalOptions[key] = options[key]
    })
  }

  outerThis.$bvModal
    .msgBoxConfirm(
      text,
      finalOptions,
    )
    .then(value => {
      if (value) {
        resolve()
      } else {
        reject()
      }
    })
})

Vue.prototype.$refreshUserInfo = outerThis => {
  outerThis.$http
    .get('/api/user')
    .then(response => {
      if (response.data.errors) {
        if (
          response.data.errors[0] === 'User not found'
          || response.data.errors[0] === 'Could not validate credentials'
        ) {
          localStorage.removeItem('userInfo')
          localStorage.removeItem('accessToken')
        }
      } else {
        outerThis.$store.commit('auth/UPDATE_USER_INFO', response.data)

        outerThis.$emit('login-state', true)
      }
    })
    .catch(() => {})
}

Vue.prototype.$logout = (outerThis, index = null, $event) => {
  if (index !== null) {
    const OtherProfiles = [...outerThis.otherProfiles]
    OtherProfiles.splice(index, 1)
    outerThis.$store.commit('auth/CHANGE_MULTIPLE_PROFILES', OtherProfiles, {
      root: true,
    })

    outerThis.$toast(
      {
        component: ToastificationContent,
        props: {
          title: 'Account Information',
          icon: 'AlertCircleIcon',
          text: 'You have logged out of the account successfully',
          variant: 'success',
        },
      },
      { timeout: outerThis.$longestTimeout },
    )
  } else if (outerThis.otherProfiles && outerThis.otherProfiles.length > 0) {
    const OtherProfiles = [...outerThis.otherProfiles]
    const profileTo = outerThis.$store.state.OtherProfiles[0]

    OtherProfiles.splice(0, 1)

    outerThis.$store.commit('auth/CHANGE_MULTIPLE_PROFILES', OtherProfiles, {
      root: true,
    })

    outerThis.$store.commit('auth/UPDATE_USER_INFO', profileTo, { root: true })
    localStorage.setItem('accessToken', profileTo.token)
    window.location.reload()
  } else {
    // localStorage.clear()
    outerThis.$store.commit('auth/UPDATE_USER_INFO', null, { root: true })
    localStorage.removeItem('userInfo')
    localStorage.removeItem('email')
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

    outerThis.$toastSuccess('Account Information', 'You have logged out of the account successfully')
    // This is just for demo Purpose. If user clicks on logout -> redirect
    outerThis.$router.push({ name: 'auth-login' })
  }
}

Vue.prototype.$replaceUrl = (outerThis, routeName, params = {}, query = {}, title = null) => {
  params = Vue.prototype.$isNotEmpty(params) ? params : outerThis.$route.params
  query = Vue.prototype.$isNotEmpty(query) ? query : outerThis.$route.query
  title = Vue.prototype.$isNotEmpty(title) ? title : window.document.title

  const updateUrl = outerThis.$router.resolve({
    name: routeName,
    params,
    query,
  }).href

  window.history.pushState(routeName, window.document.title, updateUrl)
}

Vue.prototype.$debugAlert = content => {
  if (isDebug) {
    alert(content)
  }
}
Vue.prototype.$debugLog = content => {
  if (isDebug) {
  }
}

Vue.prototype.$passwordGenerator = len => {
  const string = 'abcdefghijklmnopqrstuvwxyz'
  const numeric = '0123456789'
  const punctuation = '!@#$%^&*()_+~`|}{[]\:;?><,./-='
  let password = ''

  let hasLower = false
  let hasUpper = false

  while (password.length < len) {
    const randomStringChar = string[Math.floor(Math.random() * string.length)]
    const randomNumericChar = numeric[Math.floor(Math.random() * numeric.length)]
    const randomPunctuationChar = punctuation[Math.floor(Math.random() * punctuation.length)]

    password += randomStringChar + randomNumericChar + randomPunctuationChar

    if (string.includes(randomStringChar.toLowerCase())) {
      hasLower = true
    }

    if (string.includes(randomStringChar.toUpperCase())) {
      hasUpper = true
    }
  }

  // Add missing uppercase and lowercase characters
  if (!hasLower) {
    const randomLower = string[Math.floor(Math.random() * string.length)]
    password = password.replace(/[^a-z]/, randomLower)
  }

  if (!hasUpper) {
    const randomUpper = string.toUpperCase()[Math.floor(Math.random() * string.length)]
    password = password.replace(/[^A-Z]/, randomUpper)
  }

  password = password.split('').sort(() => Math.random() - 0.5).join('')
  return password.substr(0, len)
}

new Vue({
  sockets: {
    connect() {
      // console.log('connected!')
    },
  },
  router,
  store,
  render: h => h(App),
}).$mount('#app')
