export default [
  {
    path: '/table/users',
    name: 'candidates-data',
    component: () => import('@/views/data-manager/DataProcessor.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Candidates Data', active: true },
      ],
      pageTitle: 'Candidates Data',
      rule: 'editor',
      allowedAccountTypes: ['agency', 'employer'],
      allowAnonymous: false,
      section: 'Data Manager',
    },
  },

  {
    path: '/table/jobs',
    name: 'jobs-data',
    component: () => import('@/views/data-manager/DataProcessor.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Jobs Data', active: true },
      ],
      pageTitle: 'Jobs Data',
      rule: 'editor',
      allowAnonymous: false,
      allowedAccountTypes: ['agency'],
      section: 'Data Manager',
    },
  },
  {
    path: '/table/scheduled-jobs',
    name: 'scheduled-jobs-data',
    component: () => import('@/views/data-manager/DataProcessor.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Scheduled Jobs Data', active: true },
      ],
      pageTitle: 'Scheduled Jobs Data',
      rule: 'editor',

      allowAnonymous: false,
      allowedAccountTypes: ['agency'],
      section: 'Data Manager',
    },
  },
  {
    path: '/table/applications',
    name: 'applications-data',
    component: () => import('@/views/data-manager/DataProcessor.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Applications Data', active: true },
      ],
      pageTitle: 'Applications Data',
      rule: 'editor',

      allowAnonymous: false,
      allowedAccountTypes: ['agency'],
      section: 'Data Manager',
    },
  },

  {
    path: '/table/biweekly',
    name: 'bi-weekly-data',
    component: () => import('@/views/data-manager/DataProcessor.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Bi-Weekly Data', active: true },
      ],
      pageTitle: 'Bi-Weekly Data',
      rule: 'editor',

      allowAnonymous: false,
      allowedAccountTypes: ['agency'],
      section: 'Data Manager',
    },
  },
  {
    path: '/table/events-data',
    name: 'events-data',
    component: () => import('@/views/data-manager/DataProcessor.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Events Data', active: true },
      ],
      pageTitle: 'Events Data',
      rule: 'editor',
      
      allowAnonymous: false,
      allowedAccountTypes: ['agency', 'employer'],
      section: 'Data Manager',
    },
  },
  {
    path: '/table/crm-data',
    name: 'crm-data',
    component: () => import('@/views/data-manager/DataProcessor.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'CRM Data', active: true },
      ],
      pageTitle: 'CRM Data',
      rule: 'editor',
      allowAnonymous: false,
      allowedAccountTypes: ['agency'],
      section: 'Data Manager',
    },
  },

  {
    path: '/table/events-applications',
    name: 'candidates-events-applications',
    component: () => import('@/views/data-manager/DataProcessor.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Events Applications', active: true },
      ],
      pageTitle: 'Events Applications',
      rule: 'editor',
      allowAnonymous: false,
      allowedAccountTypes: ['agency'],
    },
  },
]
