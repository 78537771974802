export default [

  {
    path: '/applicant-tracking',
    name: 'applicant-tracking-system',
    component: () => import('@/views/applicant-tracking-system/ApplicantTracking.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Applicant Tracking System', active: true },
      ],
      pageTitle: 'Applicant Tracking System | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
      section: 'ATS System',
    },
  },

  {
    path: '/applicant-tracking/edit-job',
    name: 'applicant-tracking-system-edit',
    component: () => import('@/views/applicant-tracking-system/EditJob.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Applicant Tracking System', active: true },
      ],
      pageTitle: 'Applicant Tracking System | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
      section: 'ATS System',
    },
  },

  {
    path: '/applicant-tracking/edit-job/:job_id',
    name: 'applicant-tracking-system-edit-specific',
    component: () => import('@/views/applicant-tracking-system/EditJob.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Applicant Tracking System', active: true },
      ],
      pageTitle: 'Applicant Tracking System | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
      section: 'ATS System',
    },
  },
  {
    path: '/applicant-tracking/applications/:job_id',
    name: 'applicant-tracking-system-applications',
    component: () => import('@/views/applicant-tracking-system/Applications.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Applicant Tracking System', active: true },
      ],
      pageTitle: 'Applicant Tracking System | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
      section: 'ATS System',
    },
  },
  {
    path: '/view-resume/:resumeid',
    name: 'view-resume',
    component: () => import('@/views/jobs/ViewResume.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Applicant Resume', active: true },
      ],
      pageTitle: 'Applicant Resume | BETA',
      rule: 'editor',
      group: ['agency', 'employer'],
      allowAnonymous: false,
      section: 'ATS System',
    },
  },
]
