import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import Multiselect from 'vue-multiselect'
import ProfileCompletionForm from '@/components/ProfileCompletionForm.vue'
import FileUpload from '@/components/FileUpload.vue'
import ViewDocument from '@/components/ViewDocument.vue'

import ActionButton from '@/components/ActionButton.vue'
import ButtonSpinner from '@/components/ButtonSpinner.vue'
import SpinnerBtn from '@/layouts/components/spinners/SpinnerButton.vue'

import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import VueApexCharts from 'vue-apexcharts'
import PieChart from '@/components/charts/PieChart.vue'
import LineChart from '@/components/charts/LineChart.vue'

Vue.use(BootstrapVue)
Vue.component(FeatherIcon.name, FeatherIcon)

Vue.component('multiselect', Multiselect)
Vue.component('ProfileCompletionForm', ProfileCompletionForm)
Vue.component('FileUpload', FileUpload)
Vue.component('ViewDocument', ViewDocument)

Vue.component('ActionButton', ActionButton)
Vue.component('ButtonSpinner', ButtonSpinner)
Vue.component('SpinnerBtn', SpinnerBtn)

Vue.component('StatisticCardHorizontal', StatisticCardHorizontal)
Vue.component('StatisticCardVertical', StatisticCardVertical)
Vue.component('VueApexCharts', VueApexCharts)
Vue.component('PieChart', PieChart)
Vue.component('LineChart', LineChart)

