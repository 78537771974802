export default [
  {
    path: '/freelancers',
    name: 'freelance',
    component: () => import('@/views/freelance/Freelance.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Freelance', active: true },
      ],
      pageTitle: 'Freelance | BETA',
      rule: 'editor',
      // group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: true,
      section: 'Freelance',
    },
  },
  {
    path: '/freelancers/user/:freelancer_id',
    name: 'freelancer-information',
    component: () => import('@/views/freelance/FreelanceInformation.vue'),
    meta: {
      pageTitle: 'Freelance | BETA',
      contentClass: 'freelancer-information',
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Freelance', active: true },
      ],
    },
    allowAnonymous: true,
    section: 'Freelance-Information',
  },
  {
    path: '/freelancers/create',
    name: 'freelancer-create',
    component: () => import('@/views/freelance/CreateFreelance.vue'),
    meta: {
      pageTitle: 'Freelance | BETA',
      contentClass: 'freelancer-create',
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Freelance', active: true },
      ],
    },
    allowAnonymous: true,
    section: 'Freelance-Create',
  },
  {
    path: '/freelancers/update/:freelancer_id',
    name: 'freelancer-update',
    component: () => import('@/views/freelance/CreateFreelance.vue'),
    meta: {
      pageTitle: 'Freelance | BETA',
      contentClass: 'freelancer-update',
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Freelance', active: true },
      ],
    },
    allowAnonymous: true,
    section: 'Freelance-Update',
  },
  {
    path: '/checkout',
    name: 'marketplace-checkout',
    component: () => import('@/views/marketplace/MarketplaceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-product',
    name: 'marketplace-add-product',
    component: () => import('@/views/marketplace/MarketplaceAddProduct.vue'),
    meta: {
      pageTitle: 'Marketplace | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Add Product',
          active: true,
        },
      ],
    },
  },

  {
    path: '/marketplace-manage-products',
    name: 'marketplace-manage-products',
    component: () => import('@/views/marketplace/Marketplace.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Marketplace', active: true },
      ],
      pageTitle: 'Manage Products & Services | BETA',
      rule: 'editor',
      // group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: true,
      section: 'Marketplace',
    },
  },
  {
    path: '/marketplace-my-checkouts',
    name: 'marketplace-checkouts-list',
    component: () => import('@/views/marketplace/marketplace-checkout-list/MarketplaceCheckoutList.vue'),
    meta: {
      pageTitle: 'My Checkouts | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/marketplace-orders',
    name: 'marketplace-orders-list',
    component: () => import('@/views/marketplace/marketplace-orders-list/MarketplaceOrdersList.vue'),
    meta: {
      pageTitle: 'Orders | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/marketplace-orders/:id',
    name: 'marketplace-orders-view',
    component: () => import('@/views/marketplace/marketplace-orders-view/MarketplaceOrdersView.vue'),
    meta: {
      pageTitle: 'Checkouts | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Marketplace', active: true },
      ],
      rule: 'editor',
      // group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: true,
      section: 'Marketplace',
    },
  },
]
