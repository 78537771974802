export default [
  {
    path: '/recruitment-chatbot',
    name: 'recruitment-chatbot',
    component: () => import('@/views/chatbot-management-system/Chatbot.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Recruitment Chatbot', active: true },
      ],
      pageTitle: 'Recruitment Chatbot | BETA',
      rule: 'editor',
      section: 'Recruitment Chatbot',
      allowAnonymous: false,
    },
  },
  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/views/payments/Finance.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Finance', active: true },
      ],
      pageTitle: 'Finance | BETA',
      rule: 'editor',
      section: 'Finance',
      allowAnonymous: false,
    },
  },

  //! fix-vurnerability hide for now
  {
    path: '/employer-match',
    name: 'ea-directory',
    component: () => import('@/views/jobs/EmployerMatch.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'EA Directory', active: true },
      ],
      pageTitle: 'EA Directory | BETA',
      rule: 'editor',
      group: ['superuser', 'employer', 'education_institution'],
      section: 'EA Directory',
      allowAnonymous: false,
    },
  },

  {
    path: '/corporate-directory',
    name: 'corporate-directory',
    component: () => import('@/views/jobs/CorporateDirectory.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Corporate Directory', active: true },
      ],
      pageTitle: 'Corporate Directory | BETA',
      rule: 'editor',
      group: ['superuser', 'agency'],
      section: 'Corporate Directory',
      allowAnonymous: false,
    },
  },
  // ! fix-vurnerability disable for now
  // {
  //   path: '/corporate-directory-details',
  //   name: 'corporate-directory-details',
  //   component: () => import('@/views/jobs/CorporateDirectoryDetails.vue'),
  //   meta: {
  //     breadcrumb: [
  //       { title: 'Home', url: '/' },
  //       { title: 'Corporate Directory Details', active: true },
  //     ],
  //     pageTitle: 'Corporate Directory Details | BETA',
  //     rule: 'editor',
  //     group: ['superuser', 'agency'],
  //     section: 'Corporate Directory',
  //     allowAnonymous: false,
  //   },
  // },

  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/notifications/Notifications.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Notifications', active: true },
      ],
      pageTitle: 'Notifications | BETA',
      rule: 'editor',
      // group: ["superuser", "applicant", "employee", "elevated"],
      allowAnonymous: false,
    },
  },

  // * 202311 fix-vurnerability -> commented out for now, not migrated to postgres
  // {
  //   path: '/referral',
  //   name: 'referral',
  //   component: () => import('@/views/referrals/Referral.vue'),
  //   meta: {
  //     breadcrumb: [
  //       { title: 'Home', url: '/' },
  //       { title: 'Referrals', active: true },
  //     ],
  //     pageTitle: 'Referrals | BETA',
  //     rule: 'editor',
  //     group: ['agency'],
  //     allowAnonymous: false,
  //   },
  // },

  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Settings', active: true },
      ],
      pageTitle: 'Settings',
      rule: 'editor',
      // group: ["superuser", "applicant", "employee", "elevated"],
      allowAnonymous: false,
    },
  },

]
