<template>
  <div
    id="employment"
    class="w-full"
  >
    <div class="d-flex mb-1">
      <b-button
        id="add-employment"
        variant="warning"
        class="mx-2"
        @click="createNewEmployment"
      >Add new employment history</b-button>
    </div>

    <div class="employments-container mb-1">
      <b-row
        v-for="(item, index) in employmentInfo"
        :key="index"
        cols="10"
        class="mx-2"
      >
        <b-card
          border-variant="dark"
          bg-variant="transparent"
          class="shadow-none mx-auto"
        >
          <b-row class="w-full">
            <b-col
              cols="12"
              md="6"
              class="w-full md:w-1/2"
            >
              <label class="b-form-input--label">Position title</label>
              <b-form-input
                v-model="item.position"
                class="w-full"
                placeholder="Position title"
              />
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="w-full md:w-1/2"
            >
              <label class="b-form-input--label">Company Name</label>
              <b-form-input
                v-model="item.company_name"
                class="w-full"
                placeholder="Company Name"
              />
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="md:w-1/2 w-full mt-1"
            >
              <label class="b-form-input--label">Start Date</label>
              <div>
                <flat-pickr
                  v-model="item.start_date"
                  class="form-control"
                  :config="configdateTimePicker"
                  placeholder="Start Date"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="md:w-1/2 w-full mt-1"
            >
              <label class="b-form-input--label">End Date</label>
              <flat-pickr
                v-model="item.end_date"
                class="form-control"
                :config="configdateTimePicker"
                placeholder="End Date"
              />
            </b-col>

            <b-col
              cols="12"
              class="w-full mt-1"
            >
              <label class="b-form-input--label">Role Description</label>
              <b-form-textarea
                v-model="item.description"
                placeholder=""
                rows="2"
              />
            </b-col>
          </b-row>

          <div class="d-flex flex-row-reverse mt-1">
            <b-button
              variant="danger"
              class=""
              @click="deleteEmployment(item.id, index)"
            >Delete</b-button>
          </div>
        </b-card>
      </b-row>
    </div>

    <div class="d-flex flex-row-reverse">
      <!-- <b-button
        v-if="$route.name === 'listings-page' && employmentInfo.length > 0"
        variant="warning"
        class="mx-2 click-save"
        @click="saveEmployments"
      >Save Employment(s)</b-button> -->
      <!-- <b-button
        v-if="$route.name === 'listings-page' && employmentInfo.length > 0"
        variant="primary"
        class="mx-2 click-save"
        @click="resetEmployments"
      >Reset</b-button> -->
    </div>
  </div>
</template>

<script>
// import Datepicker from 'vuejs-datepicker'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import {
  cloneDeep, debounce, difference, orderBy,
} from 'lodash'

export default {
  components: {
    flatPickr,
  },
  data() {
    return {
      employmentInfo: [],

      configdateTimePicker: {
        inline: false,
      },

      first_run: true,
      latestUpdates: {
        employment: false,
        education: false,
        account: false,
        poc: false,
      },
    }
  },
  computed: {
    computedEmployment() {
      return cloneDeep(this.employmentInfo)
    },
  },
  watch: {
    computedEmployment: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        const outer_this = this
        if (!this.first_run && !this.latestUpdates.employment) {
          if (val && val.length === 1) {
            this.latestUpdates.employment = true
            debounce(params => {
              outer_this.updateEmployment('update', val[0].id, 0)
            }, 2000)()
          } else if (val && val.length > 1) {
            const diff = val.map((item, index) => {
              if (oldVal && oldVal.length === val.length) {
                const output = []
                Object.keys(item).forEach(key => {
                  if (oldVal[index][key] !== val[index][key]) output.push(key)
                })

                // const old_values = Object.values(oldVal[index])
                // const new_values = Object.values(item)
                // const output = difference(old_values, new_values)

                // ! VERIFY: there seems to be a bug from lodash, the commented test below does not see the difference
                // const test1 = {
                //   "company_title": "qwer",
                //   "position": "qwer",
                //   "employ_description": "",
                //   "start_date": "2022-07-01",
                //   "end_date": "",
                //   "_id": 0,
                //   "type": "update",
                //   "data_id": 0
                // }
                // const test2 = {
                //   "company_title": "qwer",
                //   "position": "qwer",
                //   "employ_description": "",
                //   "start_date": "2022-07-01",
                //   "end_date": "2022-07-20",
                //   "_id": 0,
                //   "type": "update",
                //   "data_id": 0
                // }
                // const difffff = difference(Object.values(test1), Object.values(test2))
                // console.log(`🚀 ~ file: EmploymentForm.vue ~ line 209 ~ diff ~ Object.values(test1)`, Object.values(test1))
                // console.log(`🚀 ~ file: EmploymentForm.vue ~ line 209 ~ diff ~ Object.values(test2)`, Object.values(test2))
                // console.log(`🚀 ~ file: EmploymentForm.vue ~ line 213 ~ diff ~ difffff`, difffff)

                if (output.length > 0) {
                  return index
                }
              }
            })

            for (const idx of diff) {
              if (idx !== undefined) {
                this.latestUpdates.employment = true
                debounce(() => {
                  outer_this.updateEmployment('update', val[idx].id, idx)
                }, 2000)()
              }
            }
          }
        } else {
          this.first_run = false
        }
      },
    },
  },
  created() {
    this.getEmployment()
  },
  methods: {
    getEmployment() {
      this.$http
        .get('/api/employment-data')
        .then(response => {
          this.employmentInfo = response.data.message

          this.isLoading = false
          // this.$forceUpdate();
        })
        .catch(error => {})
    },
    createNewEmployment() {
      const employmentTemplate = {
        company_name: null,
        position: null,
        description: null,
        start_date: null,
        end_date: null,
        id: null,
      }
      if (this.employmentInfo) {
        this.employmentInfo.push(employmentTemplate)
      } else {
        this.employmentInfo = [employmentTemplate]
      }
      
    },

    deleteEmployment(item_id, item_index) {
      if (item_id === 0) {
        this.employmentInfo.splice(item_index, 1)
      } else {
        this.updateEmployment('delete', item_id, item_index)
      }
    },

    saveEmployments() {
      this.employmentInfo.forEach(employment => {
        const data = employment
        data.type = 'update'
        data.data_id = employment.id
        this.$http
          .put('/api/employment-data', data)
          .then(response => {
            if (type === 'insert' || type === 'update') {
              if (Object.keys(response.data.output).length > 0) {
                if (response.data.output.id) {
                  this.employmentInfo[index].id = response.data.output.id
                }
              }
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Employment Data Update',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
              )
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Employment Data Deletion',
                    icon: 'AlertCircleIcon',
                    text: 'Data deleted successfully!',
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              this.employmentInfo.splice(index, 1)
            }
            this.isLoading = false
            this.latestUpdates.employment = false
          })
          .catch(error => {})
      })

      this.$emit('employments-updated', true)
    },

    updateEmployment(type, _id, index) {
      this.isLoading = true
      let proceed = true
      const specific_item = this.employmentInfo[index]

      if (specific_item) {
        specific_item.type = type
        specific_item.data_id = _id

        if (this.$isEmpty(specific_item.company_name)
          || this.$isEmpty(specific_item.position)
          || this.$isEmpty(specific_item.start_date)
          || this.$isEmpty(specific_item.end_date)
        ) {
          proceed = false
        }

        if (proceed) {
          this.$http
            .put('/api/employment-data', specific_item)
            .then(response => {
              if (type === 'insert' || type === 'update') {
                if (Object.keys(response.data.output).length > 0) {
                  if (response.data.output.id) {
                    this.employmentInfo[index].id = response.data.output.id
                  }
                }
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Employment Data Update',
                      icon: 'AlertCircleIcon',
                      text: response.data.message,
                      variant: 'success',
                    },
                  },
                )
              } else {
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Employment Data Deletion',
                      icon: 'AlertCircleIcon',
                      text: 'Data deleted successfully!',
                      variant: 'danger',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
                this.employmentInfo.splice(index, 1)
              }
              this.isLoading = false

              this.$emit('employments-updated', true)
            })
            .catch(error => {})
        }

        // ! VERIFY: please verify if this is okay to move out from this.$http().then(), originally was below this.isLoading = true
        this.latestUpdates.employment = false
      }
    },

    resetEmployments() {
      this.employmentInfo = []
      this.getEmployment()
    },
  },
}
</script>

<style lang="scss" scoped>
.employments-container {
  max-height: 45vh;
  overflow-y: scroll;
  overflow-x: none;
}
</style>
