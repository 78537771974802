/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { CustomerProfiles } from 'aws-sdk'
import { debounce } from 'lodash'
import useJwt from '@/auth/jwt/useJwt'

// define a mixin object
export const trackingMixin = {
  // * WAS PLANNING TO PUT IT ON router/main.js BUT IT KEEPS ADDING NEW LISTENER ON EVERY PAGE NAVIGATION
  data() {
    return {
      firstPageVisit: true,
      clickEvent: null,
      scrollEvent: null,

      eventListeners: {},
    }
  },
  watch: {
    '$route.name': function () {
      this.initScrollListener()
      this.initClickListener()

      this.trackPageVisit()

      this.initCheckSessionIsActive()
    },
  },
  created() {
    this.initScrollListener()
    this.initClickListener()

    this.initCheckSessionIsActive()

    this.$socket.client.on(`session_check_response-${localStorage.getItem('tracking_id')}`, response => {
      if (response.output.status === 'session_ended') {
        this.$http
          .post('/api/auth/logout', {
            access_token: localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName),
            tracking_id: localStorage.getItem('tracking_id'),
          })
          .then(response => {
            this.$store.commit('auth/UPDATE_USER_INFO', null, { root: true })
            localStorage.removeItem('userInfo')
            localStorage.removeItem('email')
            localStorage.removeItem('tracking_id')
            localStorage.setItem('tracking_id', this.$generateUUID4())
            localStorage.removeItem('accessToken')
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            delete this.$http.defaults.headers.common.Authorization
            this.$toastWarning('Session Ended')

            setTimeout(() => {
              if (!this.$router.currentRoute.meta.allowAnonymous) {
                this.$router.push({ name: 'auth-login' })
              }
            }, 100)
          })
          .catch(error => {
            // this.$handleErorResponse(error)
            this.isLoading = false
          })
      }
    })
  },

  mounted() {
  },

  methods: {
    initScrollListener() {
      // window.removeEventListener('scroll', event => {})
      setTimeout(() => {
        this.addTrackedEventListener(
          'scroll',
          debounce(event => {
            // console.log('init_click', this.$route)
            const form_data = {
              page_url: this.$removeUtmQuery(window.location.href),
              route_name: this.$route.name,
              tracking_id: localStorage.getItem('tracking_id'),
              event_id: localStorage.getItem('track_event_id'),
              job_id: localStorage.getItem('track_job_id'),
              type: 'scroll',
              meta: {
                client_x: event.clientX,
                client_y: event.clientY,
              },

              user_agent: navigator.userAgent,
              user_id: this.$isNotEmpty(this.$store.state.auth.ActiveUser) ? this.$store.state.auth.ActiveUser.id : null,
            }

            this.$socket.client.emit('page_activity', form_data)

            // this.$http
            //   .post('/api/page-activity-tracker', { form_data })
            //   .then(response => {
            //     const { success, output, message } = response.data
            //   })
            //   .catch(error => {
            //     //
            //   })
          }, 1000),
        )
      }, 50)
    },

    initClickListener() {
      const customEventNaming = 'page-click'
      const postPageClick = () => debounce(event => {
        const form_data = {
          page_url: this.$removeUtmQuery(window.location.href),
          route_name: this.$route.name,
          tracking_id: localStorage.getItem('tracking_id'),
          type: 'click',
          event_id: localStorage.getItem('track_event_id'),
          job_id: localStorage.getItem('track_job_id'),
          target_id: event.target.closest('[id]') ? event.target.closest('[id]').id : null,
          target_tag_name: event.target.tagName,
          target_inner_html: event.target.innerHTML.trim(),
          meta: {
            client_x: event.clientX,
            client_y: event.clientY,
            target: event.target,
            // closest_parent_with_id: event.target.closest('[id]'),
          },

          user_agent: navigator.userAgent,
          user_id: this.$isNotEmpty(this.$store.state.auth.ActiveUser) ? this.$store.state.auth.ActiveUser.id : null,
        }

        this.$socket.client.emit('page_activity', form_data)

        // this.$http
        //   .post('/api/page-activity-tracker', { form_data })
        //   .then(response => {
        //     const { success, output, message } = response.data
        //   })
        //   .catch(error => {
        //     //
        //   })
      }, 500)

      // this.removeTrackedEventListener('click', postPageClick(), customEventNaming)

      setTimeout(() => {
        this.addTrackedEventListener(
          'click',
          postPageClick(),
          customEventNaming,
        )
      }, 500)
    },

    trackPageVisit() {
      // TODO: prevent submit and creating newsession if from logout

      let utm_details = {}
      if (
        this.$isNotEmpty(localStorage.getItem('utm_source'))
        || this.$isNotEmpty(localStorage.getItem('utm_medium'))
        || this.$isNotEmpty(localStorage.getItem('utm_campaign'))
      ) {
        utm_details = {
          utm_source: localStorage.getItem('utm_source'),
          utm_medium: localStorage.getItem('utm_medium'),
          utm_campaign: localStorage.getItem('utm_campaign'),
          timestamp: new Date(),
        }
      }

      // * localStorage seems to have not loaded, was unable to use timeout
      let eventId = localStorage.getItem('track_event_id')
      if (this.$route.name === 'company-listings-page') {
        if (this.$isEmpty(eventId) && this.$isNotEmpty(this.$route.params.company)) {
          eventId = this.$route.params.company.replace('_g', '')
        }
      }

      const form_data = {
        page_url: this.$removeUtmQuery(window.location.href),
        route_name: this.$route.name,
        tracking_id: localStorage.getItem('tracking_id'),
        type: 'page_visit',
        event_id: eventId,
        job_id: localStorage.getItem('track_job_id'),
        utm_details,
        meta: {},
      }

      const result = this.$socket.client.emit('page_activity', form_data)
      if (result) {
        this.firstPageVisit = false
      }

      // this.$http
      //   .post('/api/page-activity-tracker', { form_data })
      //   .then(response => {
      //     const { success, output, message } = response.data
      //     this.firstPageVisit = false
      //   })
      //   .catch(error => {

      //     //
      //   })
    },

    initCheckSessionIsActive() {
      const customEventNaming = 'check-session-is-active'
      const postCheckSessionIsActive = () => {
        const form_data = {
          tracking_id: localStorage.getItem('tracking_id'),
          user_agent: navigator.userAgent,
          user_id: this.$isNotEmpty(this.$store.state.auth.ActiveUser) ? this.$store.state.auth.ActiveUser.id : null,
        }

        return this.$socket.client.emit('check_session_is_active', form_data)
      }

      if (this.$isEmpty(this.eventListeners[customEventNaming])) {
        this.eventListeners[customEventNaming] = setInterval(() => {
          postCheckSessionIsActive()
        }, 5000)
      }
    },

    throttle(fn, delay) {
      let timer
      return function () {
        if (!timer) {
          timer = setTimeout(() => {
            fn()
            timer = null
          }, delay)
        }
      }
    },

    addTrackedEventListener(eventName, callback, customEventNaming = null, allowMultiple = false) {
      if (this.$isEmpty(this.eventListeners[eventName])) {
        this.eventListeners[eventName] = []
      }

      if (allowMultiple || this.$length(this.eventListeners[eventName]) <= 0) {
        const listener = { customEventNaming, eventName, callback }
        this.eventListeners[eventName].push(listener)
        window.addEventListener(eventName, callback)
      }
    },
    removeTrackedEventListener(eventName, callback, customEventNaming = null) {
      window.removeEventListener(eventName, callback)
      this.eventListeners[eventName] = []

      // * NOT WORKING, BUT MIGHT BE NICE TO GET IT TO WORK
      // if (this.$isEmpty(this.eventListeners[eventName])) return

      // console.log(this.eventListeners[eventName][0])
      // let index = -1

      // if (customEventNaming) {
      //   index = this.eventListeners[eventName].findIndex(listener => listener.customEventNaming == customEventNaming)
      // }

      // if (index === -1) {
      //   index = this.eventListeners[eventName].findIndex(listener => `${listener.callback}` == `${callback}`)
      // }

      // if (index !== -1) {
      //   window.removeEventListener(eventName, callback)
      //   this.eventListeners[eventName].splice(index, 1)
      // }
    },
  },

}
