<template>
  <b-card
    no-body
    style="height: 60vh;"
  >
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
    </b-card-header>

    <b-card-body class="pb-0 h-100 mx-0">
      <!-- apex chart -->
      <b-overlay :show="isLoading" class="p-0 m-0 h-100">
        <div ref="chartContainer" style="height: 100%;">
          <vue-apex-charts
            v-if="!isLoading"
            ref="apexChart"
            :type="chartType"
            :width="`${width}`"
            :height="`${height}`"
            :options="chartOptions"
            :series="series"
          />
        </div>
          <!-- :height="`auto`" -->
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Chart',
    },
    chartData: {
      type: Object,
      default: () => {},
    }, 
    chartType: {
      type: String,
      default: 'line',
    },
    colors: {
      type: Array,
      default: () => {
        return [this.$themeColors.primary, this.$themeColors.dark, this.$themeColors.danger]
      },
    },

    width: {
      type: [String, Number],
      default: '100%',
    },
    height: {
      type: [String, Number],
      default: '100',
    },
  },
  data() {
    return {
      renderChart: true,
      series: [],
      chartInfo: {},
      chartOptions: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: this.chartType,
          dropShadow: {
            enabled: true,
            top: 18,
            left: 2,
            blur: 5,
            opacity: 0.2,
          },
          // offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          width: 4,
        },
        grid: {
          borderColor: '#ebe9f1',
          padding: {
            top: -20,
            bottom: 5,
            left: 20,
          },
        },
        legend: {
          show: false,
        },
        colors: ['#df87f2'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: [$themeColors.primary],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            offsetY: 5,
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: [],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          min: 0,
          max: 300, // Adjust max value based on your data
          tickAmount: 1,
          stepSize: 1,
          labels: {
            style: {
              colors: '#000',
              fontSize: '0.857rem',
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
            },
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
    }
  },
  watch: {
    isLoading(newVal) {
      if (newVal === false) {
        this.initComponent()
      }
    },
  },
  created() {
    this.initComponent()
  },
  mounted() {
    setTimeout(() => {
      this.height = this.$refs.chartContainer.clientHeight
    }, 500)
    // const newContainerHeight = this.$refs.chartContainer.clientHeight;
    // this.chartOptions.chart.height = '30'
  },
  methods: {
    initComponent() {
      let yMin = 0
      let yMax = 5

      this.series = []
      this.series = []
      this.chartInfo = {}

      Object.entries(this.chartData).forEach(([name, dataObjects], index) => {
        // loope thru each line to display in chart
        const categories = []
        const dataArray = []
        Object.entries(dataObjects).forEach(([key, value], dataIndex) => {
          if (dataIndex === 0) {
            yMin = value
            yMax = value
          }
          categories.push(key)
          dataArray.push(value)
          if (yMin > value) {
            yMin = value
          }
          if (yMax < value) {
            yMax = value
          }
        })

        this.series.push({
          name,
          data: dataArray,
        })
        this.chartOptions.xaxis.categories = categories
        this.chartOptions.yaxis.min = yMin >= 1 ? yMin - 1 : yMin
        this.chartOptions.yaxis.max = yMax + 1

        if ((yMax - yMin) < 50) {
          this.chartOptions.yaxis.tickAmount = 1
        }
      })

      if (this.$length(this.chartData) > 1) {
        this.chartOptions.colors = this.colors
        this.chartOptions.fill = {}
      }

      // Object.entries(this.chartData).forEach(([key, value], index) => {
      //   this.chartOptions.labels.push(key)
      //   this.series.push(value)
      //   this.chartOptions.colors.push(this.colors[index])

      //   this.chartInfo[key] = {
      //     'value': value,
      //     'color': this.colors[index],
      //   }
      // })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
