<template>
  <div class="typeform-container d-flex">
    <div
      class="question-holder"
    >
      <div class="d-flex justify-content-center">
        <b-button
          v-if="showPreviousButton"
          :disabled="disablePreviousButton"
          class="w-20 mr-1"
          variant="main"
          @click="$emit('previous-question', true)"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            size="16"
          />
        </b-button>
        <b-button
          v-if="showNextButton"
          :disabled="disableNextButton"
          class="w-20 mr-1"
          variant="main"
          @click="$emit('next-question', true)"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
          />
        </b-button>
      </div>

      <div class="mt-2">
        <h5 class="">
          {{ question }}
        </h5>

        <div class="p-1">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: String,
      default: 'question',
    },
    showPreviousButton: {
      type: Boolean,
      default: true,
    },
    showNextButton: {
      type: Boolean,
      default: true,
    },
    disablePreviousButton: {
      type: Boolean,
      default: false,
    },
    disableNextButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    previousQuestion() {
      this.$emit('previous', true)
    },
    nextQuestion() {
      this.$emit('next', true)
    },
  },
}
</script>

<style lang="scss" scoped>
.typeform-container {
  min-height: 30vh;
}

.question-holder {
  min-height: 30%;
  min-width: 100%;
  // border: 3px solid #73AD21;
  // padding: 10px;
}
</style>
