<template>
  <div
    ref="spinnerContainer"
  >
    <slot
      v-if="!isLoading"
      id="test"
      name="default"
    />
    <SpinnerBtn
      v-else
      :variant="computedVariant"
      :class="staticClass"
      :text="text"
      :style="`${$isNotEmpty(height) ? 'height:' + height + 'px;': ''} ${$isNotEmpty(width) ? 'width:' + width + 'px;': ''}`"
    />
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      defaultVariant: 'primary',

      staticClass: '',
      width: null,
      height: null,
    }
  },
  computed: {
    computedVariant() {
      if (this.$isEmpty(this.variant)) {
        try { 
          return this.$slots.default[0].devtoolsMeta.renderContext.props.variant || this.defaultVariant
        } catch (error) {
          return this.defaultVariant
        }
      } else {
        return this.variant
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.staticClass = this.$slots.default[0].data.staticClass

      // const spinnerContainer = this.$refs.spinnerContainer.querySelector('[type="button"]')
      const spinnerContainer = this.$refs.spinnerContainer.querySelector('button:nth-child(1)')

      if (spinnerContainer) {
        this.width = spinnerContainer.clientWidth
        this.height = spinnerContainer.clientHeight
      }
    }, 200)
  },
}
</script>

<style>

</style>